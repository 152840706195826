<template>
  <div id="page-login" class="h-screen flex w-full bg-img no-gutter items-center justify-center" @keyup.enter="login">
    <div class="flex justify-center sm:w-full lg:w-2/3 xl:w-3/5 xxl:w-2/5 sm:m-0 m-4">
      <vx-card class="w-full sm:w-1/2">
        <div slot="no-body" class="w-full">
          <div class="justify-center items-center">
            <!-- <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div> -->
            <div class="w-full">
              <div class="p-8">
                <div class="vx-card__title mb-8 text-center">
                  <h4 class="mb-4 text-center"> Employer Login </h4>
                  <p>Welcome back, please login to your account.</p>
                </div>
                <vs-input
                  v-model="email"
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="email"
                  label-placeholder="Email"
                  class="w-full no-icon-border"
                />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                <vs-input
                  v-model="password"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  type="password"
                  name="password"
                  icon="lock"
                  label-placeholder="Password"
                  class="w-full mt-6 no-icon-border"
                />
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                <div class="flex-col flex flex-wrap justify-between mb-5">
                  <router-link class="mb-5 mt-2 text-sm focus:underline text-rag-green-dark hover:underline" to="/pages/forgot-password">
                    Forgot Password?
                  </router-link>
                  <vs-checkbox v-model="checkbox_remember_me" class="ml-0"> Keep me signed in </vs-checkbox>
                </div>

                <vs-button v-if="!loggingIn" class="w-full" :disabled="!validateForm" @click="login"> Sign in </vs-button>
                <vs-button v-else disabled class="w-full"> Signing in </vs-button>
                <p class="text-sm mt-3 text-center"
                  >Don't have an account?
                  <span class="text-rag-green-dark cursor-pointer hover:underline focus:underline" @click="registerUser"
                    >Create an account</span
                  ></p
                >
                <!-- <vs-button type="border" @click="registerUser"> Register </vs-button> -->
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
      employers: null,
      loggingIn: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != '' && this.password != '';
    },
  },
  methods: {
    async login() {
      const isValid = await this.$validator.validate();
      if (!isValid) return;
      this.loggingIn = true;
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
        notify: this.$vs.notify,
      };
      await this.$store.dispatch('auth/loginAttempt', payload).then(() => {
        this.loggingIn = false;
      });
    },

    notifyAlreadyLoggedIn() {
      this.$vs.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        color: 'warning',
      });
    },
    registerUser() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLoggedIn();
        return false;
      }
      this.$router.push('/pages/register');
    },
  },
};
</script>

<style lang="scss">
#page-login {
  .vs-input--placeholder {
    top: auto;
  }
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }

    .bg-github {
      background-color: #333;
    }
  }
}
</style>
